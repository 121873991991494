@use 'src/css/breakpoint.scss' as *;

.wrap {
  margin-bottom: 72px;

  @include maxMediaWidth(lg) {
    margin-bottom: 30px;
  }
}

.container {
  border-bottom: 1px solid var(--delimiter-line-dark-color);
}

.innerWrap {
  margin: 30px 0 50px;

  @include maxMediaWidth(lg) {
    margin: 20px 0 30px;
  }

  @include maxMediaWidth(sm) {
    margin: 10px 0 25px;
  }
}

.title {
  margin: 0 0 10px;
  font: var(--font-h2);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font: var(--font-h2-md);
    margin-bottom: 5px;
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h2-sm);
    margin-bottom: 10px;
  }
}

.subTitle {
  font: var(--font-h8);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font: var(--font-h9-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h8-sm);
  }
}

.info {
  display: flex;
  gap: 60px;
  font: var(--font-h8);
  color: var(--lavender);
  margin-bottom: 50px;

  @include maxMediaWidth(lg) {
    font: var(--font-h9);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h11);
  }
}

.date {
  display: flex;
  align-items: center;
  gap: 8px;
}
