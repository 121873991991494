@use 'src/css/breakpoint.scss' as *;

.wrap {
  margin: 35px 0 90px;
}

.title {
  margin: 0 0 50px;
  font-size: 44px;
  text-align: center;
}

.row {
  display: flex;
  justify-content: space-between;
}

.content {
  margin-bottom: 50px;
  font: var(--font-h9);
  color: var(--secondary-text-color);

  h4 {
    font: var(--font-h5);
  }

  ul,
  ol {
    list-style-type: '-';
    margin-bottom: 20px;
    margin-left: 10px;

    li {
      padding-left: 10px;
    }
  }

  p {
    margin: 15px 0;
  }

  a {
    color: var(--accented-text-dark-color);
    text-decoration: underline;

    &:hover {
      color: var(--special-dark-color);
    }
  }

  @include maxMediaWidth(sm) {
    margin-bottom: 30px;
    font: var(--font-h11);

    h4 {
      font: var(--font-h5-sm);
    }
  }
}

.form {
  flex: 1 1 auto;
  max-width: 450px;

  @include maxMediaWidth(lg) {
    max-width: unset;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 50px 0 35px;

  @include maxMediaWidth(lg) {
    margin-bottom: 40px;
    flex-direction: row;
  }

  @include maxMediaWidth(sm) {
    margin: 30px 0;
    flex-direction: column;
  }
}

.wrapButton {
  display: flex;
  justify-content: center;

  .button {
    flex: 0 1 280px;
  }

  @include maxMediaWidth(lg) {
    justify-content: flex-start;

    .button {
      padding: 13px 50px 11px;
      font: var(--font-button-md-bold);
    }
  }

  @include maxMediaWidth(sm) {
    justify-content: center;
  }
}

.contactUsWrapper {
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 30px auto;
    padding: 0;
  }
}

.contactUs {
  margin: 0;
  padding: 43px 80px;
  border-radius: 8px;
  background: var(--join-now-gradient);
  text-align: center;

  p {
    margin: 4px 0;
  }

  @include maxMediaWidth(lg) {
    padding: 35px 64px;
  }

  @include maxMediaWidth(sm) {
    padding: 35px 20px 30px;
    border-radius: unset;
  }
}
