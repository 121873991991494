@use 'src/css/breakpoint.scss' as *;

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &.reverse {
    flex-direction: row-reverse;

    & .imgWrap:after {
      transform: rotate(45deg);
    }
  }

  &.showImageOnTablet {
    @include maxMediaWidth(lg) {
      gap: 10px;

      .imgWrap {
        display: flex;
        flex: 1 1 25%;

        &::after {
          display: block;
          left: -40%;
        }
      }
    }

    @include maxMediaWidth(sm) {
      .imgWrap {
        display: none;
      }
    }
  }
}

.contentWrapper {
  flex: 1 1 50%;

  .title {
    padding-bottom: 15px;

    @include maxMediaWidth(lg) {
      font: var(--font-h2-md);
      padding-bottom: 10px;
    }

    @include maxMediaWidth(sm) {
      font: var(--font-h5-sm);
      padding-bottom: 15px;
    }
  }

  .text {
    font: var(--font-h8);
    color: var(--secondary-text-color);
    margin: 0;

    @include maxMediaWidth(lg) {
      font: var(--font-h9-sm);
    }
  }
}

.imgWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
  width: auto;
  object-fit: contain;

  @include maxMediaWidth(lg) {
    display: none;
  }

  .image {
    object-position: center;
    object-fit: contain;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: -10%;
    bottom: 0;
    width: 546px;
    height: 546px;

    border-radius: 654px;
    background: var(--bg-image-gradient);
    filter: blur(50px);
    pointer-events: none;

    @include maxMediaWidth(lg) {
      display: none;
    }
  }
}
