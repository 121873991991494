.wrap {
  display: flex;
  width: inherit;
}

.dot {
  flex: 1 1 auto;
  margin-right: 10px;
  position: relative;
  border-radius: 50%;

  &:last-of-type {
    margin-right: 0;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.blue {
    background-color: var(--loader-primary-color);
  }

  &.yellow {
    background-color: var(--loader-secondary-color);
  }

  &.isFirst {
    animation: var(--loader-animation);
  }

  &.isSecond {
    animation: var(--loader-animation);
    animation-delay: 0.2s;
  }

  &.isThird {
    animation: var(--loader-animation);
    animation-delay: 0.2s;
  }
}
