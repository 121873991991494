.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  &.isError .input {
    margin-bottom: 10px;
    background: var(--color-warning-alpha-20);
  }
}

.light .input {
  color: var(--input-label);
  background: var(--light-bg-color);

  &::placeholder {
    color: var(--neutral-dark-color);
  }

  &:disabled {
    background: var(--light-bg-color);
  }
}

.dark .input,
.neutral .input {
  color: var(--secondary-text-color);
  background: var(--color-primary-alpha-10);

  &::placeholder {
    color: var(--neutral-dark-color);
  }

  &:disabled {
    background: var(--news-bg-dark-color);
  }
}

.neutral .input {
  color: var(--accented-text-color);
}

.label {
  font: var(--font-h11);
  color: var(--secondary-text-color);
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 10px 15px;
  min-height: 64px;
  font: var(--font-h11);
  border: none;
  border-radius: 8px;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.errorMessage {
  font: var(--font-h11);
  color: var(--error-text-color);
}
